import {useState} from 'react';
import { Card, CardContent, Typography, Avatar, Grid, Box, Button, CircularProgress } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { storage, db } from '../firebase'; // Import Firebase storage and database
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';

const ProfilePage = () => {
  const { userData, currentUser } = useAuth();
  const [uploading, setUploading] = useState(false); // Uploading state
  const [error, setError] = useState(null); // Error state

  if (!userData) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const {
    name,
    email,
    contact,
    position,
    gender,
    joinedDate,
    imageUrl,
    salary,
    medLeaves,
    annualLeaves,
    subsalesTier,
    projectTier,
  } = userData;

  // Function to get the initials if imageUrl is not provided
  const getInitials = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
  };

  // Handle file upload
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `profilePictures/${currentUser.uid}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);
    setError(null);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // You can track progress here if needed
      },
      (error) => {
        setUploading(false);
        setError('File upload failed. Please try again.');
      },
      async () => {
        // Handle successful upload
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        // Update the user's profile picture URL in Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, { imageUrl: downloadURL });

        setUploading(false);
        setError(null);
        window.location.reload(); // Refresh to show the new image
      }
    );
  };

  return (
    <Card sx={{ maxWidth: 800, margin: '0 auto', p: 2 }}>
      <Box
        sx={{
          height: 80,
          position: 'relative',
          backgroundImage: 'url(https://source.unsplash.com/random)', // Placeholder background image
          backgroundSize: 'cover',
          borderRadius: 2,
        }}
      />
      <Box sx={{ textAlign: 'center', mt: -8 }}>
        <Avatar
          alt={name}
          src={imageUrl || ''}
          sx={{
            width: 100,
            height: 100,
            margin: '0 auto',
            fontSize: 40,
            backgroundColor: '#3f51b5',
          }}
        >
          {!imageUrl && getInitials(name)}
        </Avatar>
        <Typography variant="h5" sx={{ mt: 1 }}>
          {name || 'N/A'}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {position || 'N/A'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {email}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-profile-picture"
            onChange={handleFileChange}
          />
          <label htmlFor="upload-profile-picture">
            <Button variant="contained" component="span" disabled={uploading}>
              {uploading ? <CircularProgress size={24} /> : 'Upload New Picture'}
            </Button>
          </label>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
      <CardContent>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Contact
            </Typography>
            <Typography variant="body1">{contact || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Gender
            </Typography>
            <Typography variant="body1">{gender || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Joined Date
            </Typography>
            <Typography variant="body1">{joinedDate ? new Date(joinedDate).toLocaleDateString() : 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Salary
            </Typography>
            <Typography variant="body1">RM {salary || 0}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Medical Leaves
            </Typography>
            <Typography variant="body1">{medLeaves || 0}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Annual Leaves
            </Typography>
            <Typography variant="body1">{annualLeaves || 0}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Subsales Tier
            </Typography>
            <Typography variant="body1">{subsalesTier || 0}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary">
              Project Tier
            </Typography>
            <Typography variant="body1">{projectTier || 0}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfilePage;
