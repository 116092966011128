import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import DocumentsPage from './pages/DocumentsPage';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './pages/HomePage';
import Layouts from './components/Layouts';
import UsersPage from './pages/UsersPage';
import ProfilePage from './pages/ProfilePage';
import ProjectListPage from './pages/ProjectListPage';
import PropertyDealsPage from './pages/PropertyDealsPage';
import CreateProjectDealPage from './pages/CreateProjectDealPage';
import CreateRentalDealPage from './pages/CreateRentalDealPage';
import CreateSubsaleDealPage from './pages/CreateSubsaleDealPage';
import EditRentalDealPage from './pages/EditRentalDealPage';
import ViewRentalPage from './pages/ViewRentalDealPage';
import EditProjectDealPage from './pages/EditProjectDealPage';
import ViewProjectDealPage from './pages/ViewProjectDealPage';
import EditSubsaleDealPage from './pages/EditSubsaleDealPage';
import ViewSubsaleDealPage from './pages/ViewSubsaleDealPage';

const App = () => {

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes without Layouts */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* Routes with Layouts for authenticated users only */}
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Layouts>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/sales" element={<PropertyDealsPage />}/>
                    <Route path="/sales/create-project-deal" element={<CreateProjectDealPage/>} />
                    <Route path="/sales/create-rental-deal" element={<CreateRentalDealPage/>} />
                    <Route path="/sales/create-subsale-deal" element={<CreateSubsaleDealPage/>} />
                    <Route path="/sales/edit-project-deal/:id" element={<EditProjectDealPage />} />
                    <Route path="/sales/edit-rental-deal/:id" element={<EditRentalDealPage />} />
                    <Route path="/sales/edit-subsale-deal/:id" element={<EditSubsaleDealPage />} />
                    <Route path="/sales/view-project-deal/:id" element={<ViewProjectDealPage />} />
                    <Route path="/sales/view-rental-deal/:id" element={<ViewRentalPage />} />
                    <Route path="/sales/view-subsale-deal/:id" element={<ViewSubsaleDealPage />} />
                    <Route path="/documents" element={<DocumentsPage />} />
                    <Route path="/users" element={<UsersPage />} />
                    <Route path="/profile" element={<ProfilePage/>} />  
                    <Route path="/projects" element={<ProjectListPage/>} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Layouts>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
