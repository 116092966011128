import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Avatar, CardContent, Card } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase'; // Your Firebase configuration
import { formatNumberWithCommas } from '../Helper/Helper';

const ViewProjectDealPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Use useParams to get the document ID from the URL
  const [projectDeal, setProjectDeal] = useState(null);
  const [agentNames, setAgentNames] = useState({});

  // Fetch the Project Deal from Firebase
  useEffect(() => {
    const fetchProjectDeal = async () => {
      try {
        const docRef = doc(db, 'projectDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProjectDeal(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching project deal:', error);
      }
    };

    const fetchAgentNames = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const names = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          names[doc.id] = data.name;
        });
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    fetchProjectDeal();
    fetchAgentNames();
  }, [id]);

  if (!projectDeal) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
      <Button variant="contained" color="primary" onClick={() => navigate('/sales', { state: { tabIndex: 0 } })} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        Project Deal Details
      </Typography>

      {/* Basic Information */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography>
          <Box>
            <Typography variant="body1">
              <strong>Purchaser Name:</strong> {projectDeal.purchaserName}
            </Typography>
            <Typography variant="body1">
              <strong>Project:</strong> {projectDeal.project}
            </Typography>
            <Typography variant="body1">
              <strong>Lot Number:</strong> {projectDeal.lotNumber}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* SPA & Pricing Information */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            SPA & Pricing Information
          </Typography>
          <Box>
            <Typography variant="body1">
              <strong>SPA Price:</strong> RM {formatNumberWithCommas(projectDeal.spaPrice)}
            </Typography>
            <Typography variant="body1">
              <strong>Nett Price:</strong> RM {formatNumberWithCommas(projectDeal.nettPrice)}
            </Typography>
            <Typography variant="body1">
              <strong>Rebate:</strong> RM {projectDeal.rebate}
            </Typography>
            <Typography variant="body1">
              <strong>SPA Date:</strong> {projectDeal.spaDate}</Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Loan & Referral Information */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Loan & Referral Information</Typography>
          <Box>
          <Typography><strong>Source:</strong> {projectDeal.source}</Typography>
          <Typography><strong>Referral Name:</strong> {projectDeal.referralName}</Typography>
          <Typography><strong>Co-Agency:</strong> {projectDeal.coAgency}</Typography>
          <Typography><strong>Loan Margin:</strong> {projectDeal.loanMargin}%</Typography>
          <Typography><strong>LO/LA Status:</strong> {projectDeal.loLaStatus}</Typography>
          <Typography><strong>LO/LA Sign Date:</strong> {projectDeal.loLaSignDate}</Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Documents */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
          Documents
          </Typography>
          <Box>
            <Typography><strong>Booking Form URL:</strong> <a href={projectDeal.bookingFormUrl} target="_blank" rel="noopener noreferrer">View Document</a></Typography>
            <Typography><strong>SPA Copy URL:</strong> <a href={projectDeal.spaCopyUrl} target="_blank" rel="noopener noreferrer">View Document</a></Typography>
            <Typography><strong>LO/LA Sign Copy URL:</strong> <a href={projectDeal.loLaSignCopyUrl} target="_blank" rel="noopener noreferrer">View Document</a></Typography>
            <Typography><strong>Cancellation Form URL:</strong> <a href={projectDeal.cancellationFormUrl} target="_blank" rel="noopener noreferrer">View Document</a></Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Agents Information Card */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {projectDeal.salesAgentShare.map((agent, index) => (
            <Box key={index} sx={{ mb: 2, padding: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: '#3f51b5', color: '#fff' }}>{agentNames[agent.uid]?.[0]}</Avatar>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    <strong>Sales Agent:</strong> {agentNames[agent.uid] || agent.uid}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Commission Share:</strong> {agent.sharePercentage}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
          <Typography variant="body2" gutterBottom mt={2}>
            <strong>Remarks:</strong> {projectDeal.remark}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ViewProjectDealPage;