import React, { useState, useEffect } from 'react';
import { Box, Button, Stepper, Step, StepLabel, TextField, Typography, useMediaQuery, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../firebase'; // Your Firebase config file
import { formatNumberWithCommas } from '../Helper/Helper';
import useAuth from '../hooks/useAuth';
import { fetchSalesAgents } from '../Helper/FirebaseHelper';

const steps = [
  'Basic Information',
  'SPA & Pricing Information',
  'Documents',
  'Sales Agent & Remarks'
];


const CreateSubsaleDealPage = () => {
  const theme = useTheme();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [salesAgents, setSalesAgents] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useAuth(); 
  const storage = getStorage();
  const [formData, setFormData] = useState({
    landlordName: '',
    purchaserName: '',
    projectName: '',
    lotNumber: '',
    bookingAmount: '',
    purchasePrice: '',
    bookingFormUrl: null,
    spaCopyUrl: null,
    cancellationFormUrl: null,
    commissionPercentage: '',
    commissionAmount: '',
    spaPrice: '',
    spaDate: '',
    salesAgentShare: [{ uid: 'user-uid', sharePercentage: '100' }],
    remark: '',
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
  });

  // Fetch projects from Firebase
  useEffect(() => {
    const getSalesAgents = async () => {
      const agents = await fetchSalesAgents();
      setSalesAgents(agents);
    };

    getSalesAgents();

    // Set the current user as the initial sales agent
    if (user) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        salesAgentShare: [{ uid: user.uid, sharePercentage: '100' }],
      }));
    }
  }, [user]);

  useEffect(() => {
    const calculateCommissionAmount = () => {
      const purchasePrice = parseFloat(formData.purchasePrice.replace(/,/g, '')) || 0;
      const commissionPercentage = parseFloat(formData.commissionPercentage) || 0;
      const commissionAmount = (purchasePrice * (commissionPercentage / 100)).toFixed(2);
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        commissionAmount: formatNumberWithCommas(commissionAmount),
      }));
    };

    calculateCommissionAmount();
  }, [formData.purchasePrice, formData.commissionPercentage]);

  const handleNext = () => {
    if (!isStepValid()) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;

    if (files) {
      // Handle file upload to Firebase Storage
      const file = files[0];
      if (file) {
        const fileRef = ref(storage, `documents/${file.name}`);
        await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(fileRef);

        setFormData({
          ...formData,
          [name]: fileUrl,
        });
      }
    } else if (['purchasePrice', 'commissionAmount', 'spaPrice', 'bookingAmount'].includes(name)) {
      const numericValue = value.replace(/,/g, ''); // Remove commas before parsing
      if (!isNaN(numericValue)) {
        setFormData({
          ...formData,
          [name]: formatNumberWithCommas(numericValue),
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? event.target.checked : value,
      });
    }
  };

  const saveToFirebase = async (status) => {
    try {
      const newFormData = {
        ...formData,
        status,
        createdBy: user ? user.uid : 'unknown',
        createdAt: new Date().toISOString(),
        updatedBy: user ? user.uid : 'unknown',
        updatedAt: new Date().toISOString()
      };
      await addDoc(collection(db, 'subsaleDeals'), newFormData);
      
      setSnackbarMessage(status === 'Pending' ? 'Submitted successfully!' : 'Draft saved successfully!');
      setOpenSnackbar(true);
      navigate('/sales', { state: { tabIndex: 1 } });
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleSubmit = () => {
    if (!isStepValid()) return;
    saveToFirebase('Pending'); 
    navigate('/sales'); // Redirect after submission
  };
  
  const handleDraft = () => {
    saveToFirebase('Draft');
    navigate('/sales'); // Redirect after submission
  };

  const handleAgentChange = (index, field, value) => {
    const updatedAgents = [...formData.salesAgentShare];
    updatedAgents[index][field] = value;
    setFormData({ ...formData, salesAgentShare: updatedAgents });
  };

  const addAgent = () => {
    setFormData({
      ...formData,
      salesAgentShare: [...formData.salesAgentShare, { uid: '', sharePercentage: '' }],
    });
  };

  const isStepValid = () => {
    // Validation logic for each step
    if (activeStep === 0) {
      return formData.landlordName && formData.purchaserName && formData.projectName && formData.lotNumber;
    }
    if (activeStep === 1) {
      return formData.bookingAmount && formData.purchasePrice && formData.commissionPercentage;
    }
    if (activeStep === 2) {
      return formData.bookingFormUrl;
    }
    if (activeStep === 3) {
      return formData.salesAgentShare.every(agent => agent.uid && agent.sharePercentage);
    }
    return true;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Basic Information
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Basic Information</Typography>
            <TextField 
              fullWidth 
              label="Landlord Name *" 
              name="landlordName" 
              value={formData.landlordName} 
              onChange={handleChange} 
              margin="normal" 
            />
            <TextField 
              fullWidth 
              label="Purchaser Name *" 
              name="purchaserName" 
              value={formData.purchaserName} 
              onChange={handleChange} 
              margin="normal" 
            />
            <TextField 
              fullWidth 
              label="Project *" 
              name="projectName" 
              value={formData.projectName} 
              onChange={handleChange} 
              margin="normal" 
            />
            <TextField fullWidth label="Lot Number *" name="lotNumber" value={formData.lotNumber} onChange={handleChange} margin="normal" />
          </Box>
        );
  
      case 1: // SPA & Pricing Information
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">SPA & Pricing Information</Typography>
            <TextField fullWidth label="Booking Amount (RM) *" name="bookingAmount" value={formData.bookingAmount} onChange={handleChange} margin="normal"/>
            <TextField fullWidth label="Purchase Price (RM)" name="purchasePrice" value={formData.purchasePrice} onChange={handleChange} margin="normal"/>
            <TextField 
              fullWidth 
              label="Commission Percentage (%)" 
              name="commissionPercentage" 
              value={formData.commissionPercentage} 
              onChange={(event) => {
                // Ensure the value is between 0 and 100
                const numericValue = parseFloat(event.target.value);
                if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
                  setFormData({ ...formData, commissionPercentage: event.target.value });
                } else if (event.target.value === '') { // Allow clearing the input
                  setFormData({ ...formData, commissionPercentage: '' });
                }
              }}
              type="number"
              inputProps={{
                min: 0,
                max: 100,
                step: 0.01 // Allows decimal values, adjust as needed
              }}
              margin="normal" 
            />
            <TextField fullWidth label="Commission Amount (RM)" name="commissionAmount" value={formData.commissionAmount} margin="normal" InputProps={{ readOnly: true }}/>
            <TextField fullWidth label="Rebate *" name="rebate" value={formData.rebate} onChange={handleChange} margin="normal"/>
            <TextField sx={{ width: isMobile ? '100%' : '200px' }} label="SPA Date" name="spaDate" type="date" InputLabelProps={{ shrink: true }} value={formData.spaDate} onChange={handleChange} margin="normal" />
          </Box>
        );
      case 2: // Documents
        return (
          <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6">Documents</Typography>
            <Typography variant="body1" gutterBottom>Booking Form URL *</Typography>
            <input type="file" name="bookingFormUrl" onChange={handleChange} />
            <Typography variant="body1" gutterBottom sx={{ mt: '20px' }}>SPA Copy URL</Typography>
            <input type="file" name="spaCopyUrl" onChange={handleChange} />
            <Typography variant="body1" gutterBottom sx={{ mt: '20px' }}>Cancellation Form URL</Typography>
            <input type="file" name="cancellationFormUrl" onChange={handleChange} />
          </Box>
        );
  
      case 3: // Sales Agent & Remarks
      return (
        <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Sales Agent</Typography>
            <Button variant="contained" color="success" onClick={addAgent}>
              + Add Agent
            </Button>
          </Box>
          {formData.salesAgentShare.map((agent, index) => (
            <Box key={index} sx={{ mb: 2, border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#fff' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="body1">Sales Agent: {index + 1}</Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    const updatedAgents = formData.salesAgentShare.filter((_, i) => i !== index);
                    setFormData({ ...formData, salesAgentShare: updatedAgents });
                  }}
                  sx={{ minWidth: '40px' }}
                >
                  -
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                <Box flex={1}>
                  <Typography variant="subtitle2" gutterBottom>Sales Agent</Typography>
                  <TextField
                    select
                    fullWidth
                    value={agent.uid}
                    onChange={(e) => handleAgentChange(index, 'uid', e.target.value)}
                    SelectProps={{ native: true }}
                  >
                    {/* Dynamically generate options from salesAgents */}
                    <option value="">Select Agent</option>
                    {salesAgents.map((salesAgent) => (
                      <option key={salesAgent.uid} value={salesAgent.uid}>
                        {salesAgent.name}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ width: '150px' }}>
                  <Typography variant="subtitle2" gutterBottom>Commission Share (%)</Typography>
                  <TextField
                    fullWidth
                    type="number"
                    value={agent.sharePercentage}
                    onChange={(e) => handleAgentChange(index, 'sharePercentage', e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          ))}
          <Typography variant="subtitle2" gutterBottom mt={2}>Remarks</Typography>
          <TextField
            fullWidth
            name="remark"
            value={formData.remark}
            onChange={handleChange}
            placeholder="Enter any remarks (optional)"
            multiline
            rows={3}
            variant="outlined"
          />
        </Box>
      );
  
      default:
        return 'Unknown Step';
    }
  };
  

  return (
    <Box sx={{ width: '100%' }}>
      <Button variant="contained" color="primary" onClick={() => navigate('/sales', { state: { tabIndex: 1 } })} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        New Project Deal
      </Typography>
      <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {renderStepContent(activeStep)}
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="outlined" color="primary" onClick={handleDraft}>
              Draft
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isStepValid()}>
              Submit
            </Button>
          </Box>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={!isStepValid()}>
            Next
          </Button>
        )}

      </Box>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position Snackbar at the top center
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateSubsaleDealPage;
