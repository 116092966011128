import { db } from '../firebase';
import { collection, getDocs, } from 'firebase/firestore';

// Helper function to fetch users from Firestore
export const fetchUsers = async () => {
  try {
    const usersCollection = collection(db, 'users');
    const usersSnapshot = await getDocs(usersCollection);
    const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return usersList;
  } catch (error) {
    console.error("Error fetching users: ", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

export const fetchSalesAgents = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'users'));
    // Map through the documents and return the uid and name of each sales agent
    return querySnapshot.docs.map(doc => ({
      uid: doc.id,
      name: doc.data().name,
    }));
  } catch (error) {
    console.error('Error fetching sales agents:', error);
    return [];
  }
};