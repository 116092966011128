import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { GENDERDATA, POSITIONS,  } from '../constants/data';
import { generateRandomPassword } from '../Helper/Helper';
import useAuth from '../hooks/useAuth';
import { db } from '../firebase'; 
import { doc, setDoc,} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import emailjs from 'emailjs-com';
import { fetchUsers } from '../Helper/FirebaseHelper';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TableComponent from '../components/TableComponent';

const columns = [
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'email', label: 'Email', minWidth: 100 },
  {
    id: 'position',
    label: 'Position',
    minWidth: 150,
  },
  {
    id: 'subsalesTier',
    label: 'Subsale Tier',
    minWidth: 150,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'projectTier',
    label: 'Project Tier',
    minWidth: 150,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'joinedDate',
    label: 'Joined Date',
    minWidth: 150,
    format: (value) => {
      return value ? new Date(value).toLocaleDateString() : 'N/A';
    },
  },
];



export default function UsersPage() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const { user: currentUser } = useAuth();
  const auth = getAuth();
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error message state
  const [successMessage, setSuccessMessage] = useState(""); // Success message state

  useEffect(() => {
    // Fetch data from Firestore when the component mounts
    const loadData = async () => {
      try {
        const usersList = await fetchUsers();
        setRows(usersList);
      } catch (error) {
        console.error("Error fetching users: ", error);
      } 
    };

    loadData();
  }, []); 

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: generateRandomPassword(),
    gender: GENDERDATA[0],
    position: POSITIONS[0],
    ic: "",
    contact: "",
    joinedDate: "",
    recruiterId: currentUser ? currentUser.uid : "",
    recruiterName: currentUser ? currentUser.displayName || "" : "",
    downline: [],
    insertedAt: new Date(),
    updatedAt: new Date(),
    updatedBy: currentUser ? currentUser.displayName || "" : "",
    salary: 0,
    medLeaves: 0,
    annualLeaves: 0,
    subsalesTier: 70,
    projectTier: 0,
    imageUrl: ""
  })

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false); // Stop loading when closing dialog
    setError(null); // Clear any previous errors
    setSuccessMessage(""); // Clear previous success message
    setNewUser({
      name: "",
      email: "",
      password: generateRandomPassword(),
      gender: GENDERDATA[0],
      position: POSITIONS[0],
      ic: "",
      contact: "",
      joinedDate: "",
      recruiterId: currentUser ? currentUser.uid : "",
      recruiterName: currentUser ? currentUser.displayName || "" : "",
      downline: [],
      insertedAt: new Date(),
      updatedAt: new Date(),
      updatedBy: currentUser ? currentUser.displayName || "" : "",
      salary: 0,
      medLeaves: 0,
      annualLeaves: 0,
      subsalesTier: 70,
      projectTier: 0,
      imageUrl: ""
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async () => {
    setLoading(true); // Start loading
    setError(null); // Clear any previous error
  
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password
      );
  
      const user = userCredential.user;
  
      // Convert joinedDate to a timestamp if it's provided
      const joinedDateTimestamp = newUser.joinedDate
        ? new Date(newUser.joinedDate).getTime()
        : null;
  
      // Save the new user data to Firestore with user.uid as the document ID
      await setDoc(doc(db, 'users', user.uid), {
        ...newUser,
        uid: user.uid,
        joinedDate: joinedDateTimestamp,
        insertedAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
      });
  
      // Send email notification to the new user
      await sendEmail(newUser);
  
      // Update the local state
      setRows([...rows, { ...newUser, id: user.uid }]);
      handleClose();
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        setError('This email is already in use.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const sendEmail = async (userDetails) => {
    const templateParams = {
      from_name: "Admin, Ivan",
      from_email: "lsvproperty888@gmail.com", // Replace with your admin email
      subject: "Welcome to Our Platform",
      message: `Hi ${userDetails.name}, your account has been successfully created. Your initial password is ${userDetails.password}. Please login to https://develop.lsvsystem.com/ using your email and initial password`,
      to_name: userDetails.name,
      to_email: userDetails.email,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      console.log('Email sent successfully!');
    } catch (error) {
      console.error('Email sending error:', error);
    }
  };
  
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography variant="h6" gutterBottom sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        User List
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add User
        </Button>
      </Typography>
      {loading && <CircularProgress />}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            fullWidth
            value={newUser.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={newUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="IC"
            name="ic"
            fullWidth
            value={newUser.ic}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Contact"
            name="contact"
            fullWidth
            value={newUser.contact}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Gender"
            name="gender"
            select
            fullWidth
            value={newUser.gender}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            {GENDERDATA.map((gender) => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Position"
            name="position"
            select
            fullWidth
            value={newUser.position}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            {POSITIONS.map((position) => (
              <option key={position} value={position}>
                {position}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Joined Date"
            name="joinedDate"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newUser.joinedDate}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Salary"
            name="salary"
            type="number"
            fullWidth
            value={newUser.salary}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Medical Leaves"
            name="medLeaves"
            type="number"
            fullWidth
            value={newUser.medLeaves}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Annual Leaves"
            name="annualLeaves"
            type="number"
            fullWidth
            value={newUser.annualLeaves}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="warning">
            Cancel
          </Button>
          <Button onClick={handleAddUser} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <TableComponent columns={columns} data={rows} loading={loading}/>
    </Paper>
  );
}
