import React, { useState, useEffect } from 'react';
import { TableContainer, Paper, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc, getDocs } from "firebase/firestore";
import TableComponent from '../components/TableComponent';
import useAuth from '../hooks/useAuth';

const ProjectListPage = () => {
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [open, setOpen] = useState(false);
  const [newProject, setNewProject] = useState({ name: '', commissionPercentage: '', isExcludeSST: false });
  const { user: currentUser } = useAuth();

  // Define columns for the TableComponent
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'commissionPercentage', label: 'Commission Percentage (%)', minWidth: 150 },
    { 
      id: 'isExcludeSST', 
      label: 'Exclude SST', 
      minWidth: 150, 
      format: (value) => (value ? 'Yes' : 'No')  // Format to display Yes/No
    },
  ];

  // Function to fetch projects from Firebase and assign sequential numbers
  const fetchProjects = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "projects"));
      const projects = querySnapshot.docs.map((doc, index) => ({
        id: doc.id,
        no: index + 1, 
        ...doc.data(),
        isExcludeSST: doc.data().isExcludeSST ? 'Yes' : 'No', // Ensure isExcludeSST is boolean
      }));
      setProjectData(projects);
    } catch (error) {
      console.error("Error fetching documents: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewProject({
      ...newProject,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleAddProject = async () => {
    try {
      const newProjectData = {
        name: newProject.name,
        commissionPercentage: parseFloat(newProject.commissionPercentage),
        isExcludeSST: newProject.isExcludeSST,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: currentUser ? currentUser.uid : 'unknown',
        updatedBy: currentUser ? currentUser.uid : 'unknown',
      };

      // Add new project to Firebase
      const docRef = await addDoc(collection(db, "projects"), newProjectData);

      // Update the local state with the new project
      setProjectData((prevData) => [
        ...prevData,
        {
          id: docRef.id,
          no: prevData.length + 1,
          ...newProjectData,
        }
      ]);

      // Close the modal and reset the form
      handleClose();
      setNewProject({ name: '', commissionPercentage: '', isExcludeSST: false });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" gutterBottom sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Project List
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add Project
        </Button>
      </Typography>

      <TableComponent columns={columns} data={projectData} loading={loading} />

      {/* Add Project Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Project Name"
            name="name"
            fullWidth
            value={newProject.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Commission Percentage"
            name="commissionPercentage"
            type="number"
            fullWidth
            value={newProject.commissionPercentage}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newProject.isExcludeSST}
                onChange={handleChange}
                name="isExcludeSST"
              />
            }
            label="Exclude SST"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="warning">
            Cancel
          </Button>
          <Button onClick={handleAddProject} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default ProjectListPage;
