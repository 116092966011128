import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Button, Avatar } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const ViewRentalPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rentalData, setRentalData] = useState(null);
  const [agentNames, setAgentNames] = useState({});

  useEffect(() => {
    const fetchRentalData = async () => {
      try {
        const docRef = doc(db, 'rentalDeals', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setRentalData(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching rental data:', error);
      }
    };

    const fetchAgentNames = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const querySnapshot = await getDocs(usersCollection);
        const names = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          names[doc.id] = data.name;
        });
        setAgentNames(names);
      } catch (error) {
        console.error('Error fetching agent names:', error);
      }
    };

    fetchRentalData();
    fetchAgentNames();
  }, [id]);

  if (!rentalData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Button variant="contained" color="primary" onClick={() => navigate('/sales', { state: { tabIndex: 2 } })} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        View Rental Deal
      </Typography>

      {/* Clients Information Card */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Clients Information
          </Typography>
          <Box>
            <Typography variant="body1">
              <strong>Landlord Name:</strong> {rentalData.landlordName}
            </Typography>
            <Typography variant="body1">
              <strong>Tenant Name:</strong> {rentalData.tenantName}
            </Typography>
            <Typography variant="body1">
              <strong>Project Name:</strong> {rentalData.projectName}
            </Typography>
            <Typography variant="body1">
              <strong>Lot Number:</strong> {rentalData.lotNumber}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Fees Required Card */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Fees Required
          </Typography>
          <Box>
            <Typography variant="body1">
              <strong>Rental Per Month:</strong> RM {rentalData.rentalPerMonth}
            </Typography>
            <Typography variant="body1">
              <strong>Total Rental Deposit:</strong> RM {rentalData.totalRentalDeposit}
            </Typography>
            <Typography variant="body1">
              <strong>Security Deposit:</strong> RM {rentalData.securityDeposit}
            </Typography>
            <Typography variant="body1">
              <strong>Access Card Deposit:</strong> RM {rentalData.accessCardDeposit}
            </Typography>
            <Typography variant="body1">
              <strong>Stamping Fees:</strong> RM {rentalData.stampingFees}
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Documents Card */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Documents
          </Typography>
          <Box>
            <Typography variant="body1">
              <strong>Rental Form:</strong>{' '}
              <a href={rentalData.rentalFormUploaded} target="_blank" rel="noopener noreferrer">
                View Document
              </a>
            </Typography>
            <Typography variant="body1">
              <strong>Transaction Slip 1:</strong>{' '}
              <a href={rentalData.transactionSlip1Uploaded} target="_blank" rel="noopener noreferrer">
                View Document
              </a>
            </Typography>
            <Typography variant="body1">
              <strong>Transaction Slip 2:</strong>{' '}
              {rentalData.transactionSlip2Uploaded ? (
                <a href={rentalData.transactionSlip2Uploaded} target="_blank" rel="noopener noreferrer">
                  View Document
                </a>
              ) : (
                'Not Uploaded'
              )}
            </Typography>
            <Typography variant="body1">
              <strong>Tenancy Agreement:</strong>{' '}
              <a href={rentalData.tenancyAgreementUploaded} target="_blank" rel="noopener noreferrer">
                View Document
              </a>
            </Typography>
            <Typography variant="body1">
              <strong>Stamping Certificate:</strong>{' '}
              <a href={rentalData.stampingCertUploaded} target="_blank" rel="noopener noreferrer">
                View Document
              </a>
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Agents Information Card */}
      <Card sx={{ mb: 3, boxShadow: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Agents Information
          </Typography>
          {rentalData.salesAgentShare.map((agent, index) => (
            <Box key={index} sx={{ mb: 2, padding: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: '#3f51b5', color: '#fff' }}>{agentNames[agent.uid]?.[0]}</Avatar>
                <Box>
                  <Typography variant="body1" gutterBottom>
                    <strong>Sales Agent:</strong> {agentNames[agent.uid] || agent.uid}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Commission Share:</strong> {agent.sharePercentage}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
          <Typography variant="body2" gutterBottom mt={2}>
            <strong>Remarks:</strong> {rentalData.remark}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ViewRentalPage;
