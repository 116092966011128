import { TableCell, TableRow, Box, Typography } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';

const EmptyTableComponent = ({ colSpan }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={200}>
          <InboxIcon sx={{ fontSize: 50, color: 'gray', mb: 2 }} />
          <Typography variant="body1" color="textSecondary">
            No data available
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default EmptyTableComponent;
