import React, { useState, useMemo } from 'react';
import { Box, CssBaseline, Drawer, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon, ListItemText, IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { AccountCircle, Apartment } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { LogoutRounded } from '@mui/icons-material';

const drawerWidth = 240;

const Layouts = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Modify this function to handle nested routes
  const getTitle = (path) => {
    if (path.startsWith('/sales/create-project-deal')) {
      return 'Add New Project Deal';
    } else if (path.startsWith('/sales/create-rental-deal')){
      return 'Add New Rental Deal';
    }
    else if (path.startsWith('/sales/create-subsale-deal')){
      return 'Add New Subsale Deal';
    }
    else if (path.startsWith('/sales/view-subsale-deal')){
      return 'View Subsale Deal';
    }
    else if (path.startsWith('/sales/view-project-deal')){
      return 'View Project Deal';
    }
    else if (path.startsWith('/sales/view-rental-deal')){
      return 'View Rental Deal';
    }
    else if (path.startsWith('/sales/edit-subsale-deal')){
      return 'Edit Subsale Deal';
    }
    else if (path.startsWith('/sales/edit-project-deal')){
      return 'Edit Project Deal';
    }
    else if (path.startsWith('/sales/edit-rental-deal')){
      return 'Edit Rental Deal';
    }
    switch (path) {
      case '/':
        return 'Home';
      case '/documents':
        return 'Documents';
      case '/users':
        return 'User';
      case '/projects':
        return 'Project List';
      case '/profile':
        return 'Profile';
      case '/sales':
        return 'Sales';
      default:
        return 'Home';
    }
  };

  const title = useMemo(() => getTitle(location.pathname), [location.pathname]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Adjusted active link logic
  const isActive = (link) => {
    if (link === '/') {
      return location.pathname === link; // Exact match for home
    }
    return location.pathname.startsWith(link);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap>
          LSV System
        </Typography>
      </Toolbar>
      <List>
        {[
          { text: 'Home', icon: <HomeIcon />, link: '/' },
          { text: 'Projects', icon: <Apartment />, link: '/projects' },
          { text: 'Sales', icon: <AttachMoneyIcon />, link: '/sales' },
          { text: 'Documents', icon: <DescriptionIcon />, link: '/documents' },
          { text: 'User', icon: <PersonIcon />, link: '/users' },
          { text: 'Profile', icon: <AccountCircle />, link: '/profile' },
        ].map((item) => (
          <ListItem
            key={item.text}
            component={Link}
            to={item.link}
            listItemButton={isActive(item.link)} // Updated active indicator
            sx={{
              backgroundColor: isActive(item.link) ? 'primary.light' : '', // Highlight active item
              '&:hover': {
                backgroundColor: 'primary.light', // Optional: hover effect
              },
              color: isActive(item.link) ? 'white' : 'text.primary',
            }}
          >
            <ListItemIcon sx={{ color: isActive(item.link) ? 'white' : 'text.primary' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: 'primary.light'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={handleLogout}
            startIcon={<LogoutRounded />}
            sx={{
              border: '1px solid white',
              borderRadius: 1,
              padding: '6px 16px',
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layouts;
