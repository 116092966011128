import { useState, useEffect } from 'react';
import { Box, Button, Stepper, Step, StepLabel, TextField, Typography, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatNumberWithCommas } from '../Helper/Helper';
import { db, storage } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import useAuth from '../hooks/useAuth';
import { fetchSalesAgents } from '../Helper/FirebaseHelper';

const steps = ['Basic Information', 'Property Fees', 'Documents', 'Sales Agent & Remarks'];

const CreateRentalDealPage = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth(); 
  const [salesAgents, setSalesAgents] = useState([]);

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    landlordName: '',
    tenantName: '',
    projectName: '',
    lotNumber: '',
    rentalPerMonth: '',
    readyToConvert: false,
    totalRentalDeposit: '',
    securityDeposit: '',
    accessCardDeposit: '',
    stampingFees: '',
    rentalFormUploaded: null,
    transactionSlip1Uploaded: null,
    transactionSlip2Uploaded: null,
    tenancyAgreementUploaded: null,
    stampingCertUploaded: null,
    salesAgentShare: [{ uid: 'user-uid', sharePercentage: '100' }],
    remark: '',
    createdBy: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
  });

  useEffect(() => {
    // Fetch sales agents when the component mounts
    const getSalesAgents = async () => {
      const agents = await fetchSalesAgents();
      setSalesAgents(agents);
    };

    getSalesAgents();

    // Set the current user as the initial sales agent
    if (user) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          salesAgentShare: [{ uid: user.uid, sharePercentage: '100' }],
        }));
      }
  }, [user]);

  const handleNext = () => {
    if (!isStepValid()) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleChange = async (event) => {
    const { name, type, checked, value } = event.target;
  
    if (type === 'file') {
      const file = event.target.files[0];
      if (file) {
        try {
          
          // Upload file to Firebase Storage
          const storageRef = ref(storage, `documents/${file.name}`);
          await uploadBytes(storageRef, file);
          const fileURL = await getDownloadURL(storageRef);
  
          // Update formData with the file URL
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: fileURL,
          }));
        } catch (error) {
          console.error('Error uploading file:', error);
          alert('Failed to upload file. Please try again.');
        }
      }
    } else if (['rentalPerMonth', 'totalRentalDeposit', 'securityDeposit', 'accessCardDeposit', 'stampingFees'].includes(name)) {
      // Format numeric values with commas
      const numericValue = value.replace(/,/g, ''); // Remove commas before parsing
      if (!isNaN(numericValue)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: formatNumberWithCommas(numericValue),
        }));
      }
    } else {
      // Update for other text and checkbox inputs
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };


  const saveToFirebase = async (status) => {
    try {
      const newFormData = {
        ...formData,
        status,
        createdBy: user ? user.uid : 'unknown',
        createdAt: new Date().toISOString(),
        updatedBy: user ? user.uid : 'unknown',
        updatedAt: new Date().toISOString()
      };
      await addDoc(collection(db, 'rentalDeals'), newFormData);
      
      setSnackbarMessage(status === 'Pending' ? 'Submitted successfully!' : 'Draft saved successfully!');
      setOpenSnackbar(true);
      navigate('/sales', { state: { tabIndex: 2 } });
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleSubmit = () => {
    if (!isStepValid()) return;
    saveToFirebase('Pending');
    navigate('/sales'); // Redirect after submission
  };
  
  const handleDraft = () => {
    saveToFirebase('Draft');
    navigate('/sales'); // Redirect after submission
  };

  const handleAgentChange = (index, field, value) => {
    const updatedAgents = [...formData.salesAgentShare];
    updatedAgents[index][field] = value;
    setFormData({ ...formData, salesAgentShare: updatedAgents });
  };

  const addAgent = () => {
    setFormData({
      ...formData,
      salesAgentShare: [
        ...formData.salesAgentShare,
        { uid: '', sharePercentage: '' }, // New agent data
      ],
    });
  };

  // Check if required fields are filled
  const isStepValid = () => {
    if (activeStep === 0) {
      return formData.landlordName && formData.tenantName && formData.projectName && formData.lotNumber;
    }
    if (activeStep === 1) {
      return formData.rentalPerMonth && formData.totalRentalDeposit && formData.securityDeposit && formData.accessCardDeposit && formData.stampingFees;
    }
    if (activeStep === 2) {
        return formData.rentalFormUploaded;
    }
    if (activeStep === 3) {
        return formData.salesAgentShare.every(agent => agent.uid && agent.sharePercentage);
    }
    return true;
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box display="flex" flexDirection="column" alignItems="left"  sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Clients Information</Typography>
            </Box>
            <TextField
              fullWidth
              label="Landlord Name *"
              name="landlordName"
              value={formData.landlordName}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Tenant Name *"
              name="tenantName"
              value={formData.tenantName}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Project Name *"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Lot Number *"
              name="lotNumber"
              value={formData.lotNumber}
              onChange={handleChange}
              margin="normal"
            />
          </Box>
        );
      case 1:
        return (
          <Box display="flex" flexDirection="column" alignItems="left"  sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Fees Required</Typography>
            </Box>
            <TextField
              fullWidth
              label="Rental Per Month (RM) *"
              name="rentalPerMonth"
              value={formData.rentalPerMonth}
              onChange={handleChange}
              margin="normal"
              type="text" // Use text type to handle commas
            />
            <TextField
              fullWidth
              label="Total Rental Deposit (RM) *"
              name="totalRentalDeposit"
              value={formData.totalRentalDeposit}
              onChange={handleChange}
              margin="normal"
              type="text" // Use text type to handle commas
            />
            <TextField
              fullWidth
              label="Security Deposit (RM) *"
              name="securityDeposit"
              value={formData.securityDeposit}
              onChange={handleChange}
              margin="normal"
              type="text" // Use text type to handle commas
            />
            <TextField
              fullWidth
              label="Access Card Deposit (RM) *"
              name="accessCardDeposit"
              value={formData.accessCardDeposit}
              onChange={handleChange}
              margin="normal"
              type="text" // Use text type to handle commas
            />
            <TextField
              fullWidth
              label="Stamping Fees (RM) *"
              name="stampingFees"
              value={formData.stampingFees}
              onChange={handleChange}
              margin="normal"
              type="text" // Use text type to handle commas
            />
          </Box>
        );
      case 2:
        return (
            <Box display="flex" flexDirection="column" alignItems="left"  sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Upload Documents</Typography>
              </Box>
              <Typography variant="body1" gutterBottom>
                Rental Form *
              </Typography>
              <input
                type="file"
                accept=".pdf"
                name="rentalFormUploaded"
                onChange={handleChange}
              />
              <Typography variant="body1" gutterBottom sx={{mt: '20px'}}>
                Transaction Slip 1
              </Typography>
              <input
                type="file"
                accept=".pdf"
                name="transactionSlip1Uploaded"
                onChange={handleChange}
              />
              <Typography variant="body1" gutterBottom sx={{mt: '20px'}}>
                Transaction Slip 2
              </Typography>
              <input
                type="file"
                accept=".pdf"
                name="transactionSlip2Uploaded"
                onChange={handleChange}
              />
              <Typography variant="body1" gutterBottom sx={{mt: '20px'}}>
                Tenancy Agreement
              </Typography>
              <input
                type="file"
                accept=".pdf"
                name="tenancyAgreementUploaded"
                onChange={handleChange}
              />
              <Typography variant="body1" gutterBottom sx={{mt: '20px'}}>
                Stamping Certificate
              </Typography>
              <input
                type="file"
                accept=".pdf"
                name="stampingCertUploaded"
                onChange={handleChange}
              />
            </Box>
          );
        case 3:
            return (
                <Box display="flex" flexDirection="column" alignItems="left" sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#f9f9f9' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Sales Agent</Typography>
                    <Button variant="contained" color="success" onClick={addAgent}>
                      + Add Agent
                    </Button>
                  </Box>
                  {formData.salesAgentShare.map((agent, index) => (
                    <Box key={index} sx={{ mb: 2, border: '1px solid #ddd', borderRadius: '8px', padding: '16px', backgroundColor: '#fff' }}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="body1">Sales Agent: {index + 1}</Typography>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            const updatedAgents = formData.salesAgentShare.filter((_, i) => i !== index);
                            setFormData({ ...formData, salesAgentShare: updatedAgents });
                          }}
                          sx={{ minWidth: '40px' }}
                        >
                          -
                        </Button>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                        <Box flex={1}>
                          <Typography variant="subtitle2" gutterBottom>Sales Agent</Typography>
                          <TextField
                            select
                            fullWidth
                            value={agent.uid}
                            onChange={(e) => handleAgentChange(index, 'uid', e.target.value)}
                            SelectProps={{ native: true }}
                          >
                            {/* Dynamically generate options from salesAgents */}
                            <option value="">Select Agent</option>
                            {salesAgents.map((salesAgent) => (
                              <option key={salesAgent.uid} value={salesAgent.uid}>
                                {salesAgent.name}
                              </option>
                            ))}
                          </TextField>
                        </Box>
                        <Box sx={{ width: '150px' }}>
                          <Typography variant="subtitle2" gutterBottom>Commission Share (%)</Typography>
                          <TextField
                            fullWidth
                            type="number"
                            value={agent.sharePercentage}
                            onChange={(e) => handleAgentChange(index, 'sharePercentage', e.target.value)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  <Typography variant="subtitle2" gutterBottom mt={2}>Remarks</Typography>
                  <TextField
                    fullWidth
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                    placeholder="Enter any remarks (optional)"
                    multiline
                    rows={3}
                    variant="outlined"
                  />
                </Box>
              );
      default:
        return 'Unknown Step';
    }
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Button variant="contained" color="primary" onClick={() => navigate('/sales', { state: { tabIndex: 2 } })} sx={{ mb: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        New Rental Deal
      </Typography>
      <Stepper activeStep={activeStep} sx={{mt: 4}}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {renderStepContent(activeStep)}
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="outlined" color="primary" onClick={handleDraft}>
              Draft
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!isStepValid()}>
              Submit
            </Button>
          </Box>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext} disabled={!isStepValid()}>
            Next
          </Button>
        )}

      </Box>
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position Snackbar at the top center
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateRentalDealPage;
