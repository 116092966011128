import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Button, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import TableComponent from '../components/TableComponent'; // Your reusable table component
import VisibilityIcon from '@mui/icons-material/Visibility'; // View icon
import EditIcon from '@mui/icons-material/Edit'; // Edit icon
import DeleteIcon from '@mui/icons-material/Delete'; // Delete icon
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Your firebase config file

const Project = () => {
  const navigate = useNavigate();
  const [rentalData, setRentalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    setLoading(true);
    // Fetch data from Firebase Firestore
    const fetchRentalData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'projectDeals'));
        const rentals = querySnapshot.docs.map((doc, index) => ({
          no: index + 1,
          id: doc.id,
          projectOrderId: doc.id,
          purchaserName: doc.purchaserName,
          project: doc.data().project,
          lotNumber: doc.data().lotNumber,
          status: 'Pending', // Replace or calculate status as needed
          ...doc.data(),
        }));
        setRentalData(rentals);
      } catch (error) {
        console.error('Error fetching rental data:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchRentalData();
  }, []);

  // Handlers for the action buttons
  const handleView = (row) => {
    navigate(`/sales/view-project-deal/${row.id}`);
  };

  const handleEdit = (row) => {
    navigate(`/sales/edit-project-deal/${row.id}`);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        // Delete the document from Firestore
        await deleteDoc(doc(db, 'projectDeals', selectedRow.id));
        // Update the local state to remove the deleted item
        setRentalData((prevData) => prevData.filter((item) => item.id !== selectedRow.id));
        setOpen(false); // Close the confirmation dialog
        setSelectedRow(null); // Clear the selected row
      } catch (error) {
        console.error('Error deleting document:', error);
      }
    }
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpen(true); // Open the confirmation dialog
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleAddNewDeal = () => {
    navigate('/sales/create-project-deal');
  };

  // Define columns for the table
  const columns = [
    { id: 'no', label: 'No.', minWidth: 50 },
    { id: 'projectOrderId', label: 'Project Order ID', minWidth: 150 },
    { id: 'lotNumber', label: 'Lot No.', minWidth: 150 },
    { id: 'purchaserName', label: 'Purchaser', minWidth: 150 },
    { id: 'project', label: 'Project', minWidth: 150 },
    { id: 'status', label: 'Status', minWidth: 150 },
    { id: 'bookingDate', label: 'Booking Date', minWidth: 150 },
    {
      id: 'action',
      label: 'Action',
      minWidth: 150,
      render: (row) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1, // Add some space between the icons
          }}
        >
          <IconButton color="primary" onClick={() => handleView(row)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" onClick={() => handleDeleteClick(row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Paper>
      <Typography variant="h6" gutterBottom sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Project Deal List
        <Button variant="contained" color="primary" onClick={handleAddNewDeal}>
          Add New Project
        </Button>
      </Typography>
      <TableComponent columns={columns} data={rentalData} loading={loading}/>

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this rental deal? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Project;
